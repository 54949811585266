import React, { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
const DigitalBusinessCard = React.lazy(() => import('./DigitalBusinessCard'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <DigitalBusinessCard />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;